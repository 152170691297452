import * as sanitizeHtml from "sanitize-html";

export const cleanMarkup = (str) => {
  return sanitizeHtml(str, {
    allowedTags: sanitizeHtml.defaults.allowedTags.concat(["img", "iframe"]),
    allowedAttributes: {
      img: ["src"],
      iframe: ["width", "height", "src", "title", "frameborder", "allow", "allowfullscreen"],
      h2: ["class"],
      a: ["href", "target", "referrer"],
    },
    allowedSchemes: ["data", "http", "https", "mailto"],
    parseStyleAttributes: true,
  });
};
